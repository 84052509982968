<template>
  <div>
    <!-- 时间范围-样例 -->
    <mainselect
      :myselectleftisshow="myselectleftisshow"
      :myselectleft="myselectleft"
      v-on:gettimesrange="gettimesrangeEvent"
    ></mainselect>
  </div>
</template>

<script>
import mainselect from "@/components/Mainselect";
import { ref } from "vue";

export default {
  data() {
    return {
      myselectleftisshow: true, //筛选查询-左侧字+select单框||select时间
      myselectleft: {
        width: "none",
        divinterval: "20px", //框右侧距离
        selectwidth: "200px",
        selectinterval: "", //select右侧距离
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "biaoti :",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            selectmaxwidth: "100%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
    };
  },
  methods: {
    gettimesrangeEvent(data){
      this.myselectleft.select[0].value = data
      console.log(data.valuestart.valueOf() / 1000 + ' ~ '+ data.valueend.valueOf() / 1000)
    },
  },
  components: {
    mainselect,
  },
};
</script>

<style>
</style>